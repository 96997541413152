.login-header{
  font-size: 25px;
  color: #bc222e;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: initial;
  text-align: center;
}

.login-input-box{
    border-radius: 20px !important;
}
.login-input-box:hover , .login-input-box:focus ,.login-input-box .ant-input:hover,.login-input-box .ant-input:focus  {
    border-color:#bc222e !important;
    box-shadow: 0 0 2px #bc222ea3 !important;
}

.login-btn{
background-color: #bc222e !important;
border-radius: 20px !important;
border-color: #bc222e !important;
box-shadow: 0 0 5px #bc222ea3 !important;
}
.ant-form-item-explain-error{
    color:#bc222e !important
}
.signup-link{
    font-weight: bolder;
    color: #bc222e;
}
.signup-link:hover{
    color:#eb2300
}

.select-label-key{
    color: #bc222e;
    font-weight: bold;
    line-height: 35px;
    text-align: left;
}

/* antd */
.main-layout .ant-layout{
    background: transparent ;
}
.main-layout .ant-layout .ant-layout-header{
    background: transparent ;
}
.main-layout .ant-layout .ant-layout-footer{
    background: transparent ;
}
.main-layout .ant-layout .ant-layout-sider{
    background-color: white;
    border-radius: 8px;
}
.nav-btn .ant-btn-primary{
    background-color: #bc222e;
    border-color: #bc222e;
    margin: auto 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.nav-btn .ant-btn-primary:hover,.nav-btn .ant-btn-primary:focus{
    border-color: #bc222fa8;
    background: #965b0e ;
}
.nav-btn .active-btn.ant-btn-primary{
    border-color: #bc222fa8;
    background: #965b0e ;
}
.select-wraper .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #bc222e !important;
}
.select-wraper .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: #bc222e !important;
}